import React from 'react';
import {Layout} from "antd";

const { Footer: FooterSec } = Layout;

const Footer = () => {
    return (
        <FooterSec style={{ textAlign: 'center' }}>Digital Transformation 2024 © All Rights Reserved</FooterSec>
    )
}

export default Footer;