import {PageHeader, Table, Button, Row, Input, Space, Spin, Tag} from "antd";
import React, {useRef, useState} from "react";
import FeatherIcon from "feather-icons-react";
import FontAwesome from "react-fontawesome";
import {Alert} from "../../../utils/alert";
import {Scope} from "../../../services/scopeService";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const UserList = ({isLoading, users, showModal, getUsers, getUserInfo, deleteUser, getPassInfo}) => {
    //
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters, selectedKeys, confirm, dataIndex) => {
        clearFilters();
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, selectedKeys, confirm, dataIndex)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    //
    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            ...getColumnSearchProps('username'),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            ...getColumnSearchProps('email'),
        },
        {
            title: 'Role Type',
            render: (user) => (
                <Tag color={"green"}>
                    {` ${user?.role?.name ? user.role.name : ""}`}
                </Tag>
            )
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: '_id',
            render: (key) => <>
                {Scope.checkScopes(['administration_users_update']) && (
                    <FontAwesome
                        onClick={() => getUserInfo(key)}
                        name={"edit"}
                        className="color-info"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer"}}
                    />
                )}
                {Scope.checkScopes(['administration_users_delete']) && (
                    <FontAwesome
                        onClick={() => Alert.confirm({action: deleteUser(key)})}
                        name={"trash-o"}
                        className="color-danger"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer"}}
                    />
                )}
                {Scope.checkScopes(['administration_users_update_password']) && (
                    <FontAwesome
                        onClick={() => getPassInfo(key)}
                        name={"fa-regular fa-lock"}
                        className="color-info"
                        size="2x"
                        style={{textShadow: '0 1px 0 rgba(0, 0, 0, 0.1)', cursor: "pointer"}}
                    />
                )}

            </>
        },
    ];

    const headerButtons = [
        <div key="1" className="page-header-actions">
            {Scope.checkScopes(['administration_users_create']) && (
                <Button size="small" type="primary" onClick={() => showModal('primary')}>
                    <Row>
                        <FeatherIcon icon="plus" size={14}/>
                        Add New
                    </Row>
                </Button>
            )}
        </div>,
    ];

    const screenWidth = window.innerWidth;

    return (
        <div>
            <PageHeader
                ghost={false}
                title="Users"
                subTitle={`List of all ${users.total} users.`}
                onBack={() => window.history.back()}
                extra={headerButtons}
            >
                <Table
                    pagination={{pageSize: 50, showSizeChanger: false}}
                    rowKey="_id"
                    bordered={false}
                    className="table-responsive"
                    loading={!users?.data?.length ? <Spin  className="example"/> : ""}
                    dataSource={users.data}
                    columns={columns}
                    responsive={true}
                    scroll={screenWidth > 700 ? { x: 'min-content', y: 'max-content'  } : { x: 'max-content', y: 'min-content'  }}
                />
            </PageHeader>
        </div>
    )
};

export default UserList;
