import React, {Fragment, Suspense, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Badge, Button, Card, Col, Form, PageHeader, Row, Skeleton, Statistic} from 'antd';
import Section from "../../../components/layout/Section";
import {
    addSurgeon,
    fetchSurgeonForProfile, fetchSurgeonProfile, updateSurgeonProfile,
    uploadImage
} from "../../../redux/patient-management/surgeons/actionCreator";
import {Auth} from "../../../utils/auth";
import FormData from "./form";
import ImageForm from "./imageForm";
import {
    fetchWeekSchedulesForSurgeons,
    fetchSurgeonCancelledSchedule,
    fetchSurgeonAllSchedule
} from "../../../redux/schedule-management/schedules/actionCreator";
import {Cards} from "../../../components/cards/frame/cards-frame";
import {UserBioBox, UserCard} from "../../style/styled";
import moment from "moment/moment";
import Meta from "antd/es/card/Meta";
import email from "../../../static/others/email.jpg";
import phone2 from "../../../static/others/phone2.jpg";
import fax from "../../../static/others/fax.jpg";
import address from "../../../static/others/home.jpg";
import {CameraOutlined, EditOutlined, PlusSquareTwoTone} from "@ant-design/icons";


const NewProfile = (props) => {
    const dispatch = useDispatch();
    const getUsers = Auth.getUserInfo()
    const usersAsSurgeons = useSelector(state => state.umUsers.users_surgeons);
    const surgeon = useSelector(state => state.pmSurgeons.surgeon);
    const surgeonSchedules = useSelector(state => state.smSchedules.surgeonSchedules);
    const surgeonCancelledSchedules = useSelector(state => state.smSchedules.surgeonCancelledSchedules);
    const surgeonAllSchedules = useSelector(state => state.smSchedules.surgeonAllSchedules);
    const [form] = Form.useForm();
    const [header, setHeader] = useState("active");

    const getSurgeonInfo = (_id) => dispatch(fetchSurgeonProfile(_id, showModalEdit));
    // const getImageInfo = (_id) => dispatch(fetchSurgeonProfile(_id, showImageModalEdit));

    console.log("surgeonSchedules", surgeonSchedules)

    const [postData, setPost] = useState ({
        selectedFile: "",
        logo: "",
        signature: ""
    })

    const [state, setState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });

    const [imageState, setImageState] = useState({
        visible: false,
        modalType: 'primary',
        colorModal: false,
        uploading: false,
    });

    const showModalEdit = async () => {
        await setState({
            ...state,
            visible: true,
            modalType: "primary",
        });
        await form.resetFields();
    };

    const showImageModalEdit = async () => {
        await setImageState({
            ...imageState,
            visible: true,
            modalType: "primary",
        });
        // await form.resetFields();
    };

    const handleOk = async () => {
        await dispatch(fetchSurgeonForProfile(getUsers._id));
        setState({
            ...state,
            visible: false,
            colorModal: false,
        });
    };

    const [lockUpload, setLockUpload] = useState(false)

    const handleImageOk = async () => {
        setImageState({
            ...imageState,
            visible: false,
            colorModal: false,
        });
        setPost({...postData, selectedFile: "", logo: "", signature: ""})
        setLockUpload(true)
    };

    useEffect(() => {
        const fetchData = async () => {
            if (getUsers && getUsers?.surgeon?._id) await dispatch(fetchWeekSchedulesForSurgeons(getUsers._id));
            // await dispatch(fetchUsersAsSurgeons())
            await dispatch(fetchSurgeonForProfile(getUsers._id));
        };
        fetchData().then(r => {
        });
    }, [dispatch, surgeon._id]);


    const headerButtons = [
        <div key="1" className="page-header-action">
            <Button
                size="medium"
                type={header === "active" ? "primary" : "default"}
                className="mr-1"
                onClick={() => setHeader("active")}
            >
                Active
            </Button>
            <Button
                size="medium"
                type={header === "cancelled" ? "primary" : "default"}
                className="mr-1"
                onClick={() => {
                    setHeader("cancelled")
                    dispatch(fetchSurgeonCancelledSchedule(getUsers._id))
                }
                }
            >
                Cancelled
            </Button>
            <Button
                size="medium"
                type={header === "all" ? "primary" : "default"}
                className="minimum-mr"
                onClick={() => {
                    setHeader("all")
                    dispatch(fetchSurgeonAllSchedule(getUsers._id))
                }
                }
            >
                All
            </Button>
        </div>
    ];

    const screenWidth = window.innerWidth;
    const today = moment().format("YYYY-MM-DD")

    const ActiveSchedules = ({visible, surgeon}) => {
        return (
            <div style={{display: visible ? "block" : "none"}}>
                <Row gutter={16}>
                    {surgeonSchedules?.map((item) => {
                        return (
                            <Col span={ screenWidth < 1300 ? 24 : 8}>
                                <Card className="mt-2 ml-1" size={"small"} bordered={true}>
                                    <Badge className="float-right m-2" size="default" color="green" dot="dot" />
                                    <Statistic
                                        title={`${item?.date ?  moment(item.date).format("MM-DD-YYYY") : ""}`}
                                        value={" "}
                                        valueStyle={{color: '#27869b'}}
                                        suffix={
                                            <>
                                                <div className="mb-2">
                                                    <small  style={{fontSize: "11px"}}><b>{item?.startTime ? moment(item.startTime , ["HH.mm"]).format("hh:mm a") + " " : ""}</b></small>
                                                    <small style={{fontSize: "11px"}}>{item?.patient?.firstName + " " + item?.patient?.lastName}</small>
                                                    <br/>
                                                    <PlusSquareTwoTone style={{fontSize:"12px"}} className="pb-0" /><small style={{fontSize: "11px"}} className="text-wrap">{item?.procedure?.map((item) => " " + item.surgery)}</small>
                                                    <br/>
                                                </div>
                                            </>
                                        }
                                    />
                                </Card>
                            </Col>
                        )
                    })
                    }
                </Row>
            </div>
        )
    }

    const CancelledSchedules = ({visible, surgeon}) => {
        return (
            <div style={{display: visible ? "block" : "none"}}>
                <Row gutter={16}>
                    {surgeonCancelledSchedules?.map((item) => {
                        return (
                            <Col span={ screenWidth < 1300 ? 24 : 8}>
                                <Card className="mt-2 ml-1" size={"small"} bordered={true}>
                                    <Badge className="float-right m-2" size="default" dot="dot" />
                                    <Statistic
                                        title={`${item?.date ?  moment(item.date).format("MM-DD-YYYY") : ""}`}
                                        value={" "}
                                        valueStyle={{color: '#27869b'}}
                                        suffix={
                                            <>
                                                <div className="mb-2">
                                                    <small  style={{fontSize: "11px"}}><b>{item?.startTime ? moment(item.startTime , ["HH.mm"]).format("hh:mm a") + " " : ""}</b></small>
                                                    <small style={{fontSize: "11px"}}>{item?.patient?.firstName + " " + item?.patient?.lastName}</small>
                                                    <br/>
                                                    <PlusSquareTwoTone style={{fontSize:"12px"}} className="pb-0" /><small style={{fontSize: "11px"}} className="text-wrap">{item?.procedure?.map((item) => " " + item.surgery)}</small>
                                                    <br/>
                                                </div>
                                            </>
                                        }
                                    />
                                </Card>
                            </Col>
                        )
                    })
                    }
                </Row>
            </div>
        )
    }

    const AllSchedules = ({visible, surgeon}) => {
        return (
            <div style={{display: visible ? "block" : "none"}}>
                <Row gutter={16}>
                    {surgeonAllSchedules?.map((item) => {
                        return (
                            <Col span={ screenWidth < 1300 ? 24 : 8}>
                                <Card className="mt-2 ml-1" size={"small"} bordered={true}>
                                    {item?.scheduleCancelStatus ? (<Badge className="float-right m-2" size="default" dot="dot" />) :
                                        moment(item.date).format("YYYY-MM-DD") >= today ? (<Badge className="float-right m-2" size="default" color="green" dot="dot" />) :
                                            (<Badge className="float-right m-2" size="default" color="yellow" dot="dot" />)
                                    }
                                    <Statistic
                                        title={`${item?.date ?  moment(item.date).format("MM-DD-YYYY") : ""}`}
                                        value={" "}
                                        valueStyle={{color: '#27869b'}}
                                        suffix={
                                            <>
                                                <div className="mb-2">
                                                    <small  style={{fontSize: "11px"}}><b>{item?.startTime ? moment(item.startTime , ["HH.mm"]).format("hh:mm a") + " " : ""}</b></small>
                                                    <small style={{fontSize: "11px"}}>{item?.patient?.firstName + " " + item?.patient?.lastName}</small>
                                                    <br/>
                                                    <PlusSquareTwoTone style={{fontSize:"12px"}} className="pb-0" /><small style={{fontSize: "11px"}} className="text-wrap">{item?.procedure?.map((item) => " " + item.surgery)}</small>
                                                    <br/>
                                                </div>
                                            </>
                                        }
                                    />
                                </Card>
                            </Col>
                        )
                    })
                    }
                </Row>
            </div>
        )
    }

    const Board = [
        <>
            <Col >
                <Suspense
                    fallback={
                        <Cards headless>
                            <Skeleton avatar active />
                        </Cards>
                    }
                >
                    <UserCard>
                        <div className="card user-card">
                            <Card
                                cover={
                                    <div style={{ overflow: "hidden", height: "200px" }}>
                                        <img
                                            className={ screenWidth < 700 ? "m-auto p-2" : "m-auto p-2"}
                                            alt="example"
                                            style={{ height: "220px", width: screenWidth < 700 ? "220px" : "220px", borderRadius: "10px" }}
                                            src={`${surgeon.file ? surgeon.file : null}`}
                                        />
                                    </div>
                                }
                                actions={[
                                    <>
                                        <Avatar className="" size="large" src={surgeon.logo ? surgeon.logo : null}/>
                                    </>
                                ]}
                            >
                                <Meta
                                    title={`${surgeon?.firstName ? surgeon.firstName.toUpperCase() + " " + surgeon.lastName.toUpperCase() : ""}`}
                                />
                                <Meta
                                    description={`${surgeon?.department ? surgeon.department : ""} Dept.`}
                                />
                            </Card>
                        </div>
                    </UserCard>
                </Suspense>
                <UserBioBox>
                    <Card
                        bordered={false}
                        actions={[
                            <>
                                {
                                    surgeon?._id ? (
                                        <Row className="float-right">
                                            {/*<EditOutlined className="mr-3" key="edit" onClick={() => getSurgeonInfo(surgeon._id)}/>*/}
                                            <CameraOutlined key="imgEdit" onClick={showImageModalEdit}/>
                                        </Row>
                                    ) : null
                                }
                            </>
                        ]}
                    >
                        <article className="user-info">
                            <Row>
                                <Col >
                                    <Card
                                        size="small"
                                        bordered={false}
                                        cover={
                                            <Fragment>
                                                <img alt="signature" style={{width: "130px"}} src={`${surgeon.signature ? surgeon.signature : null}`}/>
                                                <Meta
                                                    description={`Printed Date: ${surgeon?.dateOfSign ? moment(surgeon.dateOfSign).format('MM-DD-YYYY') : null}`}
                                                />
                                            </Fragment>
                                        }
                                    >
                                    </Card>
                                </Col>
                            </Row>
                            <Meta
                                avatar={<Avatar src={email} size={20}/>}
                                title={
                                    <span style={{fontSize: "small"}}>
                                    {surgeon?.userInfo?.email ? surgeon.userInfo.email : ""}
                                    </span>}
                            />
                            <br/>
                            <Meta
                                avatar={<Avatar src={phone2} size={20}/>}
                                title={<span style={{fontSize: "small"}}>{surgeon?.phone ? surgeon.phone : ""}</span>}
                            />
                            <br/>
                            <Meta
                                avatar={<Avatar src={fax} size={20}/>}
                                title={<span style={{fontSize: "small"}}>{surgeon?.fax ? surgeon.fax : ""}</span>}
                            />
                            <br/>
                            <Meta
                                avatar={<Avatar src={address} size={20}/>}
                                style={{width: "175px", textAlign: "justify", textJustify: "inter-word"}}
                                description={<span style={{fontSize: "small"}}>{surgeon?.address ? surgeon.address : ""}</span>}
                            />
                        </article>
                    </Card>
                </UserBioBox>
            </Col>
            <Col md={12} xxl={18} xl={14} >
                <PageHeader
                    className="page-header"
                    ghost={false}
                    extra={headerButtons}
                >
                    <ActiveSchedules visible={header === "active"} surgeon={surgeon} />
                    <CancelledSchedules visible={header === "cancelled"} surgeon={surgeon} />
                    <AllSchedules visible={header === "all"} surgeon={surgeon} />
                </PageHeader>
            </Col>
        </>
    ]

    return (
        <Section style={{marginTop: '15px'}}>
            {
                screenWidth > 700 ? (
                    <>
                        <Row gutter={25}>
                            {Board}
                        </Row>
                    </>
                ) : (
                    <div>
                        {Board}
                    </div>
                )
            }
            <FormData
                state={state}
                form={form}
                surgeon={surgeon}
                addSurgeon={addSurgeon}
                usersAsSurgeons={usersAsSurgeons}
                updateSurgeon={updateSurgeonProfile}
                handleOk={handleOk}
            />
            <ImageForm
                postData={postData}
                setPost={setPost}
                state={imageState}
                surgeon={surgeon}
                uploadImage={uploadImage}
                fetchSurgeonForProfile={fetchSurgeonForProfile}
                handleOk={handleImageOk}
            />
        </Section>

    )
}

export default NewProfile;