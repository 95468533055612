import React from "react";
import {Layout, Row, Col, Form, Input, Button} from "antd";
import {login} from "../../redux/authentication/actionCreator";
import {useDispatch} from "react-redux";
import mainLogo from "../../static/images/main_logo.png"
import {AuthWrapper} from "./style";
const { Content: ContentSec } = Layout;

const Login = () => {
    const dispatch = useDispatch();

    const onFinish = (values) => {
        dispatch(login(values))
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Layout style={{ minHeight: '100vh'}}>
            <br/>
            <br/>
            <br/>
            <AuthWrapper>
                <div className="auth-contents" >
                    <Form
                        name="login"
                        initialValues={{ remember: true }}
                        className="login-form"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        layout="vertical"
                        style={{border: "green 0.5px solid"}}
                    >
                        <Row className="text-center">
                            <img src={mainLogo} alt="image" style={{maxHeight: 130, paddingBottom: 20}}/>
                            <Col className="gutter-row" span={8}>
                            </Col>
                            <Col className="gutter-row" span={4}>
                                <button className="N-Btn">
                                    D E M O Server
                                </button>
                            </Col>

                        </Row>
                        <Form.Item
                            name="email"
                            rules={[{ message: 'Please input your Email!', required: true }]}
                            style={{padding: 10}}
                            label="Email or Username"
                        >
                            <Input size="large" placeholder={'Email or Username'}/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[{ message: 'Please input your Password!', required: true }]}
                            style={{padding: 10}}
                            label="Password">
                            <Input size="large" placeholder="Password" type={'password'}/>
                        </Form.Item>
                        <Form.Item className="text-center">
                            <Button style={{textAlign:"center"}} className="btn-signin mt-10" htmlType="submit" type="primary" size="large">
                              Sign In
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </AuthWrapper>
        </Layout>
    )
}

export default Login;
